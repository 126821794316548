/// <reference path="../../_all.ts" />
/// <reference path="./../../shared/services/brookson.services.member.ts"/>
/// <reference path="../../shared/modules/brookson.navigation.ts" />
/// <reference path="../models/member/TfaMemberContactDetails.ts" />
/// <reference path="../../shared/models/member/TfaMemberContactDetails.ts" />
module Shared {
    export interface IAuthenticationService {
        login(loginData): ng.IPromise<any>;
        updateAccessTokenServiceLine(id, serviceLine, refreshToken, apiKeySource): ng.IPromise<any>;
        refreshToken(service_line?);
        logout();
        activateMember(memberId, registrationId, password): ng.IPromise<boolean>;
        requestPasswordReset(memberId): ng.IPromise<boolean>;
        resetMemberPassword(id, memberId, password): ng.IPromise<boolean>;
        verifyShareholderLogin(memberId, registrationId, password): ng.IPromise<boolean>;
        requestForgottenClientId(firstName: string, surname: string, email: string, dateOfBirth: string): ng.IPromise<boolean>;
        validateLoginRequest(memberId: string, password: string): ng.IPromise<any>;
        twoFactorAuthRequest(contactAddressNumber: string, memberId: number): ng.IPromise<boolean>;
        twoFactorAuthValidation(otp: string, memberId: number): ng.IPromise<shared.TwoFactorAuthValidationResponse>;
        getMemberMaskedContactDetails(memberId: number): ng.IPromise<shared.MemberContactDetails>;
        getMemberContactDetails(memberId: number): ng.IPromise<shared.MemberContactDetails>;
        saveTwoFactorAuthSetting(memberId: number, enableTfa: boolean): ng.IPromise<boolean>;
    }

    interface ActivationRequest {
        memberId: string;
        registrationId: string;
        password: string;
    }
    interface ResetPasswordRequest {
        id: string;
        memberId: string;
        password: string;
    }
    interface TokenResponse {
        access_token: string;
    }
    interface ShareholderVerificationRequest {
        username: string;
        registrationId: string;
        password: string;
    }

    interface ForgottenClientIdRequest {
        firstName: string;
        surname: string;
        email: string;
        dateOfBirth: string;
    }
    interface ValidateUserRequest {
        memberId: string;
        password: string;
    }
    interface TwoFactorAuthRequest {
        contactAddressNumber: string;
        memberId: number;
    }

    interface TwoFactorAuthValidation {
        otp: string;
        memberId: number;
    }

    interface MemberContactDetailsRequest {
        memberId: number;
    }

    interface SaveTfaSettingRequest {
        memberId: number;
        enableTfa: boolean;
    }

    export class AuthenticationService implements IAuthenticationService {
        static $inject = ["$injector", "$httpParamSerializer", "$q", "shared.config", "memberSrv", "brookson.navigation"];

        public serviceBase: string;
        public authSettings: any;
        public _authentication: any = {
            isAuth: false,
            userName: "",
            useRefreshTokens: false
        };
        public $http: ng.IHttpService;

        constructor(
            private $injector: ng.auto.IInjectorService,
            private $httpParamSerializer: ng.IHttpParamSerializer,
            private $q: ng.IQService,
            private sharedConfig: any,
            private memberSrv: Shared.IMemberService,
            private brooksonNavigation: Shared.IBrooksonNavigation
        ) {
            this.serviceBase = sharedConfig.baseApiUrl;
            this.authSettings = sharedConfig.authSettings;
        }

        validateLoginRequest = (memberId: string, password: string): ng.IPromise<any> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/TwoFactorAuth/ValidateLoginRequest";

            const request: ValidateUserRequest = {
                memberId: memberId,
                password: password
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                },(err): void => {
                    console.log(err.data);
                })
                .catch((err) => {
                    console.log(err.data);
                });

        }

        twoFactorAuthRequest = (contactAddressNumber: string, memberId: number): ng.IPromise<boolean> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/TwoFactorAuth/TwoFactorAuthRequest";

            const request: TwoFactorAuthRequest = {
                contactAddressNumber: contactAddressNumber,
                memberId: memberId
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        twoFactorAuthValidation = (otp: string, memberId: number): ng.IPromise<shared.TwoFactorAuthValidationResponse> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/TwoFactorAuth/TwoFactorAuthValidation";

            const request: TwoFactorAuthValidation = {
                otp: otp,
                memberId: memberId
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<shared.TwoFactorAuthValidationResponse>): shared.TwoFactorAuthValidationResponse => {
                    return response.data;
                });
        }

        getMemberMaskedContactDetails = (memberId: number): ng.IPromise<shared.MemberContactDetails> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/TwoFactorAuth/GetMaskedMemberContactDetails";

            const request: MemberContactDetailsRequest = {
                memberId: memberId
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<shared.MemberContactDetails>): shared.MemberContactDetails => {
                    return response.data;
                });
        }

        getMemberContactDetails = (memberId: number): ng.IPromise<shared.MemberContactDetails> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/TwoFactorAuth/GetMemberContactDetails";

            const request: MemberContactDetailsRequest = {
                memberId: memberId
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<shared.MemberContactDetails>): shared.MemberContactDetails => {
                    return response.data;
                });
        }

        saveTwoFactorAuthSetting = (memberId: number, enableTfa: boolean): ng.IPromise<boolean> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/TwoFactorAuth/SaveTwoFactorAuthSetting";

            const request: SaveTfaSettingRequest = {
                memberId: memberId,
                enableTfa: enableTfa
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });            
        }

        login = (loginData): ng.IPromise<any> => {
            let deferred = this.$q.defer();

            const data = {
                grant_type: "password",
                username: loginData.userName,
                password: loginData.password,
                client_id: "",
                scope: [loginData.sessionId, loginData.apiKeySource],
            };

            if (this.authSettings.useRefreshToken) {
                data.client_id = this.authSettings.clientId;
            }

            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");

            this.$http.post(this.serviceBase + "token",
                this.$httpParamSerializer(data),
                {
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded"
                    }
                })
                .then(
                    (response: any): void => {
                        let refreshToken = "";

                        if (this.authSettings.useRefreshToken) {
                            refreshToken = response.data.refresh_token;
                        }

                        if (response.data.access_token) {
                            const result = {
                                token: response.data.access_token,
                                memberId: Number(response.data.userName),
                                userName: Number(response.data.userName),
                                isAuthenticated: true,
                                refreshToken: refreshToken,
                                useRefreshToken: this.authSettings.useRefreshToken,
                                isAdmin: response.data.is_admin === 'true' ? true : false,
                                expires: response.data['.expires'],
                                refreshTokenExpires: response.data.clientRefreshTokenExpires,
                                apiKeySource: response.data.apiKeySource
                            };

                            deferred.resolve(result);
                        } else {
                            deferred.reject(response.data);
                        }
                    },
                    (err): void => {
                        deferred.reject(err.data);
                    }
                );

            return deferred.promise;
        }

        updateAccessTokenServiceLine = (id, serviceLine, refreshToken, apiKeySource): ng.IPromise<any> => {
            let deferred = this.$q.defer();

            const data = {
                member_id: id,
                company_ref: serviceLine.customerReference,
                alt_member_id: serviceLine.altMemberId,
                business_type: serviceLine.businessType,
                role: serviceLine.role,
                navReferral: serviceLine.navReferral,
                apiKeySource: apiKeySource
            };

            this.refreshToken(data)
                .then((response: TokenResponse) => {
                    if (response.access_token) {
                        deferred.resolve(response);
                    } else {
                        deferred.reject(response);
                    }
                })
                .catch((err) => {
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        refreshToken = (service_line) => {
            var deferred = this.$q.defer();

            const member = this.memberSrv.getMember();

            if (member && member.useRefreshToken) {

                const data = {
                    grant_type: "refresh_token",
                    refresh_token: member.refreshToken,
                    client_id: this.authSettings.clientId,
                    set_service_line: false,
                    member_id: "",
                    alt_member_id: "",
                    company_ref: "",
                    business_type: "",
                    role: "",
                    navReferral: "",
                    apiKeySource: ""
                };

                if (service_line) {
                    data.set_service_line = true;
                    data.member_id = service_line.member_id;
                    data.alt_member_id = service_line.alt_member_id;
                    data.company_ref = service_line.company_ref;
                    data.business_type = service_line.business_type;
                    data.role = service_line.role;
                    data.navReferral = service_line.navReferral;
                    data.apiKeySource = service_line.apiKeySource;
                }

                this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");

                this.$http.post(this.serviceBase + "token",
                    this.$httpParamSerializer(data),
                    { headers: { 'Content-Type': "application/x-www-form-urlencoded" } })
                    .then((response: any) => {
                        var data = response.data;
                        var result = <Shared.MemberModel>{
                            token: data.access_token,
                            userName: data.userName,
                            isAuthenticated: true,
                            refreshToken: data.refresh_token,
                            useRefreshToken: true,
                            expires: data['.expires'],
                            refreshTokenExpires: data.clientRefreshTokenExpires,
                            apiKeySource: service_line == undefined ? null : service_line.apiKeySource
                        };
                        this.memberSrv.updateMember(result);
                        deferred.resolve(data);
                    }).catch(err => deferred.reject(err));
            } else {
                deferred.reject();
            }

            return deferred.promise;
        };

        logout = (): void => {
            this.memberSrv.clearMember();
            this.brooksonNavigation.home.logout();
        }

        activateMember = (memberId: string, registrationId: string, password: string): ng.IPromise<boolean> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");

            const url: string = this.serviceBase + "api/Member/ActivateMember";

            const request: ActivationRequest = {
                memberId: memberId,
                registrationId: registrationId,
                password: password
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        requestPasswordReset = (memberId: string): ng.IPromise<boolean> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");

            const url: string = this.serviceBase + "api/Member/RequestPasswordResetSms/" + memberId;

            return this.$http.get(url)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        requestForgottenClientId = (firstName: string, surname: string, email: string, dateOfBirth: string): ng.IPromise<boolean> => {

            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");

            const url: string = this.serviceBase + "api/Member/RequestForgottenClientId";

            const request: ForgottenClientIdRequest = {
                firstName: firstName,
                surname: surname,
                email: email,
                dateOfBirth: dateOfBirth
            };
            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        resetMemberPassword = (id: string, memberId: string, password: string): ng.IPromise<boolean> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");

            const url: string = this.serviceBase + "api/Member/resetMemberPassword";

            const request: ResetPasswordRequest = {
                id: id,
                memberId: memberId,
                password: password
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        verifyShareholderLogin = (userName: string, registrationId: string, password: string): ng.IPromise<boolean> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");

            const url: string = this.serviceBase + "api/Member/verifyShareholderLogin";

            const request: ShareholderVerificationRequest = {
                username: userName,
                registrationId: registrationId,
                password: password
            };

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

    }
}

angular
    .module('app.shared')
    .service('authenticationSrv', Shared.AuthenticationService);