/// <reference path="../../shared/models/member/TfaMemberContactDetails.ts" />

module Login {
    export class BrooksonTwoFactorAuth implements ng.IComponentOptions {
        public controller: Function = TwoFactorAuthController;
        public templateUrl: string = 'src/app/login/views/twoFactorAuth.html';
    }

    class TwoFactorAuthController {

        public selectedCommms: string;
        public emailAddress: string;
        public showOTP: boolean = false;
        public otp: string;
        public message: string = "";
        public isLoading: boolean = false;
        public contactDetailList: shared.MemberContactDetails;
        public authRequestError: boolean = false;
        public otpError: boolean = false;
        public submitted: boolean = false;
        public intervalId: number = 0;
        public otpExpired: boolean;
        public timer: any = null;
        private otpTimer: any;

        static $inject = ['$window', '$state', '$stateParams', 'serviceLineSrv', 'memberSrv', 'authenticationSrv', 'focus', 'brookson.navigation', 'brookson.notifications.manager', '$interval', '$scope'];
        constructor(private $window: Window,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private serviceLineSrv: Shared.IServiceLineService,
            private memberSrv: Shared.IMemberService,
            private authenticationSrv: Shared.IAuthenticationService,
            private focus: any,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private brooksonNotificationsManager: Shared.IBrooksonNotificationManager,
            public $interval: ng.IIntervalService,
            private $scope: ng.IScope
        ) {
            this.isLoading = true;
        }

        $onInit = (): void => {
            if (!this.$stateParams["loginData"]) {
                this.$state.go("login.login");
            } else {
                this.authenticationSrv.getMemberMaskedContactDetails(this.$stateParams["loginData"].userName).then((memberContactDetails: shared.MemberContactDetails) => {
                    if (memberContactDetails !== null) {
                        this.contactDetailList = memberContactDetails;
                    }
                    this.isLoading = false;
                });
                this.$scope.$on("$destroy", () => {
                    this.stopTimer();
                });
            }
        }

        submit = (isValid: boolean): void => {
            this.submitted = true;
            this.otpError = false;
            this.authRequestError = false;  
            if (this.showOTP) {
                this.authenticationSrv.twoFactorAuthValidation(this.otp, this.$stateParams["loginData"].userName).then((response: shared.TwoFactorAuthValidationResponse) => {
                    if (response !== null && response !== undefined) {
                        if (response.accountLockOut) {
                            this.stopTimer();
                            this.$state.go("login.login");
                        } else {
                            if (response.validationResponse) {
                                this.stopTimer();
                                this.loginProcess(this.$stateParams["loginData"]);
                            } else {
                                // Wrong OTP, try again.
                                this.focus('otp');
                                this.otpError = true;
                                this.submitted = false;
                            }
                        }
                    } else {
                        this.focus('otp');
                        this.otpError = true;
                        this.submitted = false;
                    }
                });
            } else {
                if (this.selectedCommms !== undefined) {
                    $('.countdown').text('');
                    this.authenticationSrv.twoFactorAuthRequest(this.selectedCommms, this.$stateParams["loginData"].userName).then((response: boolean) => {
                        if (response) {
                            this.showOTP = true;
                            this.focus('otp');
                            this.startTimer();
                        } else {
                            this.authRequestError = true; 
                        }
                        this.submitted = false;
                    });
                }
            }
            
        }

        startTimer = (): void => {
            var duration;
            var interval = 1000;
            duration = moment.duration({ 'minutes': 2 });
            $('.countdown').text(duration.format('mm:ss'));

            if (angular.isDefined(this.otpTimer)) {
                this.stopTimer();
            }
            this.otpExpired = false;

            this.otpTimer = this.$interval(() => {
                duration = moment.duration(duration - interval, 'milliseconds');
                if (duration > 1000) {
                    if (duration > 60000) {
                        $('.countdown').text(duration.format('mm:ss'));
                    } else {
                        $('.countdown').text(duration.format('ss') + ' seconds');
                    }
                } else {
                    this.otpExpired = true;
                    $('.countdown').text('');
                }
            }, 1000);

        }

        stopTimer = (): void => {
            if (angular.isDefined(this.otpTimer)) {
                this.$interval.cancel(this.otpTimer);
                this.otpTimer = undefined;
            }
        }

        loginProcess = (loginData): void => {
            this.authenticationSrv.login(loginData)
                .then((result) => {

                    if (this.$window.brookson.serverLogin != null && this.$window.brookson.serverLogin.hasOwnProperty('sessionId')) {
                        result.apiKeySource = result.apiKeySource;
                        delete this.$window.brookson.serverLogin['sessionId'];
                    }

                    let member: Shared.MemberModel = this.memberSrv.setMember(result);

                    if (this.$window.brookson.serverLogin) {
                        if (this.$window.brookson.serverLogin.InternalLogin)
                            member.navReferral = this.$window.brookson.serverLogin.userNameString.toString();
                        if (this.$window.brookson.serverLogin.ApiKeySource !== null && this.$window.brookson.serverLogin.ApiKeySource !== '') {
                            member.navReferral = this.$window.brookson.serverLogin.ApiKeySource + "-" + this.$window.brookson.serverLogin.userNameString.toString().replace('@people20.com', '');
                            member.apiKeySource = this.$window.brookson.serverLogin.ApiKeySource;
                        }
                    }

                    this.brooksonNotificationsManager.clearNotificationsShown();

                    if (member.isAdmin) {
                        this.$state.go("login.super", this.$stateParams);
                    } else {
                        this.serviceLineSrv.getServiceLines(member)
                            .then((lines) => {
                                if (lines.length) {
                                    member.serviceLines = lines;

                                    var hasMultipleServiceLines = _.keys(_.groupBy(lines, 'customerReference')).length > 1;

                                    if (hasMultipleServiceLines) {
                                        this.$state.go("login.serviceLine", this.$stateParams);
                                    } else {
                                        member.businessType = lines[0].businessType;
                                        member.role = lines[0].role;
                                        member.companyRef = lines[0].customerReference;
                                        lines[0].navReferral = member.navReferral;
                                        this.authenticationSrv.updateAccessTokenServiceLine(member.memberId, member.serviceLines[0], member.refreshToken, member.apiKeySource)
                                            .then((data): void => {
                                                // If is leaver, go to self assessment
                                                if (member.serviceLines[0].isEligible) {
                                                    this.brooksonNavigation.personalTax.selfAssessment();
                                                    return;
                                                }

                                                if (this.$stateParams['ReturnUrl']) {
                                                    this.$window.location.href = this.$stateParams['ReturnUrl'];
                                                } else {
                                                    this.brooksonNavigation.dashboard.main();
                                                }

                                                // Umbrella users should go to Connect 2.0
                                                if (member.businessType.toLowerCase() === 'umbrella' && !this.$window.appSettings.umbEnabled) {
                                                    this.$window.location.href = this.$window.appSettings.connect2Url + '/Login.aspx?lo=a25';
                                                    return;
                                                }
                                            })
                                            .catch((): void => { });
                                    }

                                    this.memberSrv.setMember(member);
                                } else {
                                    this.message = "Unable to find service lines for user.";
                                }
                            }, (err): void => {
                                this.message = err.error_description;
                            });
                    }
                },
                    (err): void => {
                        this.message = err.error_description;
                    }).then(() => {
                        
                    });
        }

        disableSubmitButton = (): boolean => {
            if (!this.showOTP) {
                return this.selectedCommms === undefined || this.submitted === true;
            } else {
                return this.otp === undefined || this.otp === null || this.otp === '' || this.submitted === true;
            }
        }

        reset = (): void => {
            this.stopTimer();
            this.otp = undefined;
            this.showOTP = false;
            this.otpExpired = false;
        }
    }
}