/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/services/brookson.services.authentication.ts" />
/// <reference path="../../shared/services/brookson.services.service-line.ts" />
/// <reference path="../../shared/services/brookson.services.member.ts" />
/// <reference path="./../../shared/modules/brookson.notifications.manager.ts"/>
/// <reference path="../../shared/modules/brookson.navigation.ts" />
/// <reference path="../../shared/interfaces/brookson.interfaces.window.ts" />
/// <reference path="../../shared/models/member/validateUserResponse.ts" />
/// <reference path="../models/loginData.ts" />
module Login {
    export class BrooksonLoginLogin implements ng.IComponentOptions {
        public controller: Function = LoginCtrlController;
        public templateUrl: string = "src/app/login/views/login.html";
    }

    class LoginCtrlController {
        static $inject = ["$window", "$state", '$stateParams', "authenticationSrv", "serviceLineSrv", "memberSrv", 'focus', 'brookson.notifications.manager', 'brookson.navigation', 'localStorageService'];

        public email: string = "";
        public password: string = "";
        public apiSessionId;
        public apiKeySource: string = "";
        public message: string = "";
        public requestInProgress: boolean = false;
        constructor(
            private $window: Window,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private authenticationSrv: Shared.IAuthenticationService,
            private serviceLineSrv: Shared.IServiceLineService,
            private memberSrv: Shared.IMemberService,
            private focus: any,
            private brooksonNotificationsManager: Shared.IBrooksonNotificationManager,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private localStorageService: ng.local.storage.ILocalStorageService
        ) { 
            this.memberSrv.clearMember();
        }

        $onInit = (): void => {
            if (this.$window.brookson.serverLogin) {
                this.serverLogin();
            }
            this.localStorageService.remove("brookson.2faSetup");
            this.focusOnUsernameInput();
        }

        serverLogin = (): void => {
            this.email = this.$window.brookson.serverLogin.UserName;
            this.password = this.$window.brookson.serverLogin.Password;
            this.apiSessionId = this.$window.brookson.serverLogin.sessionId;
            this.apiKeySource = this.$window.brookson.serverLogin.ApiKeySource;
            this.login(true);
        }

        login = (isValid: boolean): void => {

            let loginData = new Login.LoginData();
            loginData.userName = this.email;
            loginData.password = this.password;
            loginData.sessionId = this.apiSessionId;
            loginData.apiKeySource = this.apiKeySource;

            if (!isValid) {
                this.message = "Form invalid!";
                return;
            }

            this.requestInProgress = true;

            if ((this.$window.brookson.serverLogin === null || this.$window.brookson.serverLogin === undefined) && this.$window.appSettings.twoFactorEnabled === 'True') { // ignore any API requests from D365 or Internal Connect
                
                this.authenticationSrv.validateLoginRequest(loginData.userName, loginData.password).then((result: Shared.ValidatedUserLoginResponse) => {
                    if (result !== null)
                    {
                        if (result.validLoginRequest) {
                            if (this.$window.appSettings.enable2FAForAllMembers === 'True') {
                                if (result.twoFactorEnabled) {
                                    // open the 2FA page and send OTP
                                    this.$state.go("login.twoFactorAuth", { loginData: loginData });
                                } else {
                                    this.localStorageService.set("brookson.2faSetup", true);
                                    this.loginProcess(loginData);
                                }
                            } else {
                                this.loginProcess(loginData);
                            }
                        } else {
                            this.message = result.loginMessage;
                            this.focusOnPasswordInput();
                            this.requestInProgress = false;
                        }
                    }
                    else
                    {  
                        this.message = 'The user name or password is incorrect.';
                        this.focusOnPasswordInput();
                        this.requestInProgress = false;
                    }
                });
            } else {
                this.loginProcess(loginData);
            }

        }

        loginProcess = (loginData): void => {
            this.authenticationSrv.login(loginData)
                .then((result) => {

                    if (this.$window.brookson.serverLogin != null && this.$window.brookson.serverLogin.hasOwnProperty('sessionId')) {
                        result.apiKeySource = result.apiKeySource;
                        delete this.$window.brookson.serverLogin['sessionId'];
                    }

                    let member: Shared.MemberModel = this.memberSrv.setMember(result);

                    if (this.$window.brookson.serverLogin) {
                        if (this.$window.brookson.serverLogin.InternalLogin)
                            member.navReferral = this.$window.brookson.serverLogin.userNameString.toString();
                        if (this.$window.brookson.serverLogin.ApiKeySource !== null && this.$window.brookson.serverLogin.ApiKeySource !== '') {
                            member.navReferral = this.$window.brookson.serverLogin.ApiKeySource + "-" + this.$window.brookson.serverLogin.userNameString.toString().replace('@people20.com', '');
                            member.apiKeySource = this.$window.brookson.serverLogin.ApiKeySource;
                        }
                    }

                    this.brooksonNotificationsManager.clearNotificationsShown();

                    if (member.isAdmin) {
                        this.$state.go("login.super", this.$stateParams);
                    } else {
                        this.serviceLineSrv.getServiceLines(member)
                            .then((lines) => {
                                if (lines.length) {
                                    member.serviceLines = lines;

                                    var hasMultipleServiceLines = _.keys(_.groupBy(lines, 'customerReference')).length > 1;

                                    if (hasMultipleServiceLines) {
                                        this.$state.go("login.serviceLine", this.$stateParams);
                                    } else {
                                        member.businessType = lines[0].businessType;
                                        member.role = lines[0].role;
                                        member.companyRef = lines[0].customerReference;
                                        lines[0].navReferral = member.navReferral;
                                        this.authenticationSrv.updateAccessTokenServiceLine(member.memberId, member.serviceLines[0], member.refreshToken, member.apiKeySource)
                                            .then((data): void => {
                                                // If is leaver, go to self assessment
                                                if (member.serviceLines[0].isEligible) {
                                                    this.brooksonNavigation.personalTax.selfAssessment();
                                                    return;
                                                }

                                                if (this.$stateParams['ReturnUrl']) {
                                                    this.$window.location.href = this.$stateParams['ReturnUrl'];
                                                } else {
                                                    this.brooksonNavigation.dashboard.main();
                                                }

                                                // Umbrella users should go to Connect 2.0
                                                if (member.businessType.toLowerCase() === 'umbrella' && !this.$window.appSettings.umbEnabled) {
                                                    this.$window.location.href = this.$window.appSettings.connect2Url + '/Login.aspx?lo=a25';
                                                    return;
                                                }
                                            })
                                            .catch((): void => { });
                                    }

                                    this.memberSrv.setMember(member);
                                } else {
                                    this.message = "Unable to find service lines for user.";
                                    this.focusOnPasswordInput();
                                }
                            }, (err): void => {
                                this.message = err.error_description;
                                this.focusOnPasswordInput();
                            });
                    }
                },
                (err): void => {
                    this.message = err.error_description;
                    this.focusOnPasswordInput();
                }).then(() => {
                    this.requestInProgress = false;
                });
        }

        focusOnPasswordInput = (): void => {
            this.focus('password');
        }

        focusOnUsernameInput = (): void => {
            this.focus('username');
        }
    }
}